/* Cart.css */

.close-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.close-btn svg {
  font-size: 18px;
}

.close-btn .text {
  text-transform: uppercase;
  font-size: 14px;
}

.close-btn:hover {
  opacity: 0.5;
}

.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding:20px
}

.empty-cart svg {
  font-size: 120px;
  opacity: 0.1;
}

.opac-layer {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button .checkout-cta {
  outline: 0;
  border: 0;
  height: 50px;
  width: 100%;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background:black;
  border-bottom: 3px solid black;
}

.cart-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cart-header .heading {
  flex-grow: 1;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.cart-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 99;
  /* right: 2px; */
}
.cart-panelstyleadd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  /* z-index: 99; */
}

.cart-content {
  /* width: 100%; */
  height: 100%;
  border-radius: 15px 0 0 15px;
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;
  animation: slideCartWindow 0.3s ease forwards;
}

.cart-content::-webkit-scrollbar {
  width: 10px;
}

.cart-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.cart-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.cart-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@keyframes slideCartWindow {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.truncate-text {
  display: inline-block;
  width: 300px;
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  max-width: 300px;
  /* background-color: rebeccapurple; */
}
.quantityContainer {
 
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  border: 1px solid #ccc;
}
.pricemaincontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
@media screen and (max-width: 600px) {
  /* .cart-content {
    overflow: hidden;
  } */
  .button .checkout-cta {
    outline: 0;
    border: 0;
    height: 50px;
    display: flex;
    /* align-items: center;
        justify-content: center; */
    cursor: pointer;
    font-size: 16px;
    color: white;
    background: black;
    border-bottom: 3px solid black;
   
  }
  .close-btn .text {
    text-transform: uppercase;
    font-size: 14px;
  }
  .cart-footer .subtotal .text {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .cart-text-container {
    width: 250px;
  }
}

.custom-scrollbar-container::-webkit-scrollbar {
  width: 10px;
}

/* .custom-scrollbar-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
}


.custom-scrollbar-container ::-webkit-scrollbar-thumb {
  background: #888;
}


.custom-scrollbar-container ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.buttonstyle{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    width:'100%';
    background-color: black;
    color: whitesmoke;
    font-size: 18px;
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textinputStyle{
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    border-radius: 20px solid black;
    border-width: 1px;
}
.ReactModalPortal > div{
    background-color: rgb(255, 255, 255,0.3) !important;
}
.salonkart{
    font-weight:900;
    font-size: 20px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.salonkartcontianer{
    display: flex;
    /* background-color: rebeccapurple; */
    /* justify-content: center; */
    /* align-items: center; */
    justify-content: space-between;
}
.totalprice{
    font-weight: 900;
    font-size: 20px;
    color:gray
}
.freeshipping{
    font-size: 18px;
    color:white;
    background-color: black;
    padding: 13px;
    border-width: 1px;
    border-radius: 20px;
}
.modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;   z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
 }
 .modal__wrapper {
    position: absolute;
    top: 50%;
    width: 30%;
    background: #fff;
    text-align: center;
    transform: translateY(-50%);
    padding: 2rem;
    border-radius: 18px;
    box-shadow: 0 0 1rem 0 #ccc;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .textinputStyle{
    /* padding-top: 10px;
    padding-bottom: 10px; */
    width: 100%;
    border-radius: 10px;
    border-width: 1px;
    padding: 10px;

}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Stardos+Stencil:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.stardos-stencil-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
  }
body,html{
  width: 100%;
  
  max-width: 100vw;
  overflow-x: hidden;
}
.font-Inter{
  font-family: "Inter", sans-serif!important;

}
.css-ahj2mt-MuiTypography-root{
  font-family: "Inter", sans-serif!important;
}
.dark{
 
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 60%), black;
   
}
  .roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .merriweather-regular {
    font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
  }
  
  
  .font-grotesk {
    font-family: "Schibsted Grotesk", serif;

  }

  .merriweather-black {
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .merriweather-bold-italic {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: italic;
  }

  .sedan-sc-regular {
    font-family: "Sedan SC", serif;
    font-weight: 400;
    font-style: normal;
  }
  .loader {
    border: 4px solid black;
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  .sans-serif {
    font-family: sans-serif;
  }
  
  @keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }

  .loading-dots {
    animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.slide-in {
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

.slide-out {
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 8px!important;
  bottom: -10px!important;
  width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
}
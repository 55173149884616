.navbar-container {
    display: flex;
    align-items: center;
    margin-left: 21px;
    margin-top: 6px;
    width: 92vw;
  }
  
  .smaller-container {
    display: flex;
    gap: 59px;
    margin-top: 9px;
    justify-content: space-around;
  }
  
  .small-firstlogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .small-logo {
    width: 44vw;
    margin-left: 30px;
    font-weight: bold;
  }
  
  .small-user-cart-container {
    display: flex;
    gap: 7px;
    margin-right: 12px;
  }
  
  .small-user-icon {
    width: 25px;
    height: 24px;
  }
  
  .small-kart-icon {
    width: 25px;
    height: 24px;
  }
  
  .hamburger {
    color: white;
    margin-left: 10px;
    margin-top: 5px;
    font-weight: bold;
    font-size: 50px;
    
  }
  
  /* .small-search-container {
    height: 34px;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }
  
  .small-search-input {
    width: 81vw;
    margin-left: 17px;
    margin-top: 4px;
  }
  
  .small-search-icon {
    position: absolute;
    right: 64px;
    top: 52px
  } */
  .small-search-container {
    position: relative;
    display: inline-block;
    height: 34px;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }
  
  .small-search-input {
    width: 81vw;
    /* margin-left: 17px; */
    margin-top: 4px; 
  }
  
  .small-search-icon {
    position: absolute;
    top: 50%;
    right: 5px; 
    transform: translateY(-50%);
    background-color: white;
  }
  .header-item--logo {
    flex: 0 0 auto;
    word-break: break-all;
}
  .header-item {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
}
.header-item.header-item--logo {
  flex: none !important;
}
.header-item.header-item--icons {
  flex: none !important;
}
  .cart-link__bubble{
   
      top: -2px;
      right: 0;
      color: #ffff;
    
        position: absolute;
        font-size: 12px;
        line-height: 1;
        font-weight: 600;
        letter-spacing: 0;
        text-align: center;
    
  }
  .cart-link__bubble:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color:#56d200;
    border-radius: 50px;
    width: 150%;
    height: 0;
    padding: 10px;
    transform: translate(-50%,-50%);
}
  /* Optional: Style the search icon */
  /* .small-search-icon img {
    width: 20px; 
    height: 20px; 
  } */
  
  .firstlogo {
    width: 28vw;
    margin-right: 11px;
  }
  
  .top-heading {
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  
  .brand-dropdown {
    position: absolute;
    top: 92px;
    left: 105px;
    background: #fff;
    padding: 18px 5px;
    border: 1px solid #ccc;
    z-index: 2;
    width: 121px;
  
    
  }
  
  .brand-dropdown a {
    display: block;
    padding: 4px 8px;
    color: #333;
    text-decoration: none;
  }
  
  .brand-dropdown a:hover {
    background: #eee;
  }
  
  .mobileview {
    background-color: rebeccapurple;
  }
  
  /* .logo {
    filter: grayscale(100%);
    width: 23vw;
  } */
  
  .search-container {
    width: 100%;
    position: relative;
    border: 1px solid #000;
    border-radius: 50px;
    margin-left: 3px;
    margin-top: 3px;
  }
  
  .search-input {
    padding: 2px 10px;
  
    height: 40px;
    width: 100%;
    outline: none;
  }
  
  .search-icon {
    position: absolute;
    top: 0;
    right: 22px;
    margin-top: 8px;
    margin-right: 5px;
  }
  
  .menu-icon {
    display: none;
    width: 20px;
    height: 20px;
  }
  
  .skinco-logo {
    width: auto;
    height: auto;
    margin-left: 20px;
  }
  
  .argatin-logo {
    width: auto;
    height: auto;
    margin-left: 30px;
  }
  
  .user-icon {
    width: auto;
    height: auto;
  }
  
  .user-cart-container {
    display: flex;
    justify-content: space-between;
    column-gap: 28px;
    margin-left: 53px;
    width: 32px;
    margin-right: 30px;
  }
  
  .cart-container {
    display: flex;
    justify-content: space-between;
  }
  
  .kart-icon {
    width: auto;
    height: auto;
  }
  
  .mobile-menu {
    display: block;
  }
  
  .menu-item {
    display: flex;
    margin: 1px;
  }
  
  .arrow-icon {
    margin-left: 240px;
    margin-top: 8px;
  }
  
  .submenu {
    margin-left: 30px;
  }
  
  .dropdown-menu {
    position: absolute;
    width: 14vw;
    /* height: 30vh; */
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    top: 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    right: 20px;
    z-index: 1000;
  }
  
  .dropdown-menulogout {
    position: absolute;
    width: 14vw;
    height: 25vh;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    top: 50px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    right: 85px;
    z-index: 1000;
  }
  
  .productsubcategory-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    
  }
  
  .fade-out-top {
      -webkit-animation: fade-out-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
              animation: fade-out-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 47px;
    color: #333333;
    cursor: pointer;
  }
  
  /* ----------------------------------------------
   * Generated by Animista on 2024-10-4 10:30:53
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  
  /**
   * ----------------------------------------
   * animation fade-out-top
   * ----------------------------------------
   */

/*Animate hamburger icon when nav open*/
.mobile-nav-trigger path, .site-nav__compress-menu path {
  transition: all 0.3s cubic-bezier(0.18, 0.77, 0.58, 1);
}
.mobile-nav-trigger.is-active path:nth-child(1), .site-nav__compress-menu.is-active path:nth-child(1) {
    transform: rotate(45deg);
    transform-origin: 20% 30%;
  }
.mobile-nav-trigger.is-active path:nth-child(2), .site-nav__compress-menu.is-active path:nth-child(2) {
    opacity: 0;
  }
.mobile-nav-trigger.is-active path:nth-child(3), .site-nav__compress-menu.is-active path:nth-child(3) {
    transform: rotate(-45deg);
    transform-origin: 15% 66%;
  }

  .slide-in-top {
    -webkit-animation: slide-in-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) 100ms both;
            animation: slide-in-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) 100ms both;
  }
  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  
   @-webkit-keyframes fade-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
              z-index: 50;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
              z-index: 0;
              pointer-events: none;
  
      opacity: 0;
    }
  }
  @keyframes fade-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
              z-index: 50;
              opacity: 1;  }
    100% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
              z-index: 0;
             pointer-events: none;
  
              opacity: 0;  }
  }
  
  
  /* Add this to your stylesheet */
  
  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
    }
  
    to {
      transform: translateX(0);
    }
  }
  
  .hamburger-container {
    transform: translateX(-100%);
    /* Start off-screen to the left */
    animation: slideFromLeft 0.8s ease;
    /* Adjust duration for a slower effect */
  }
  
  /* Add this to your stylesheet */
  
  /* Add this to your stylesheet */
  
  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
    }
  
    to {
      transform: translateX(0);
    }
  }
  
  .hamburger-container {
    transform: translateX(-100%);
    /* Start off-screen to the left */
    animation: slideFromLeft 0.11s ease forwards;
    /* Use 'forwards' to keep final state */
  }
  
  .hamburger-container.hidden {
    transform: translateX(-100%);
    /* Move off-screen to the left when hidden */
  }
  
  .hamburger-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 85%;
    height: 100%;
    /* background-color: #edeade; */
    background-color: black;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    z-index: 1000;
  }
  
  .hamburger-container h1 {
    font-size: 50px;
    margin: 10px;
    color: #333333;
  }
  
  .hamburger-container h1 {
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .hamburger-container.hidden {
    display: none;
  }
  
  .text-container {
    display: flex;
    flex-direction: column;
    color: black;
    margin-top: 111px;
    margin-left: 21px;
    border-radius: -33px;
    padding: 18px;
    width: 70vw;
    font-size: 10px;
  }
  
  .close-button:hover {
    color: #ff0000;
    /* Change color on hover if desired */
  }
  
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 23px;
    position: relative;
    padding: 12px;
    text-decoration: none;
  }
  
  .nav-text::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  @media screen and (max-width: 600px) {
    .productsubcategory-container {
      display: none;
    }
    .small-search-icon{
      right: 52px;
    }
  
    .brands-container {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid black;
      padding-bottom: 10px;
    }
  
    .optionsbrand {
      padding: 22px 19px;
      line-height: 40px;
    }
    
  
    .brand-link {
      font-size: 18px;
      margin: 10px 0;
      transition: background-color 0.3s;
    }
  
    .optionsbrand .brand-link:hover {
      background-color: red;
    }
  
    .brandtext {
      font-size: 22px;
      padding-left: 10px;
    }
  
    .brandaicon {
      margin-top: 2px;
      font-size: 27px;
    }
  
    .hamburger {
      display: block;
      color: white;
      font-size: 30px;
    }
  
    .dropdown-menu {
      background-color: black;
      border: 1px solid #ddd;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: opacity 0.3s ease, transform 0.3s ease;
      top: 200px;
      border-radius: 10px;
      cursor: pointer;
      margin-bottom: 10px;
      right: 22px;
      position: absolute;
      z-index: 30;
      height: 236px;
      width: 182px;
      background-color: white;
      border: 1px solid #ddd;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }
  
    .dropdown-menulogout {
      height: auto;
      width: 200px;
      background-color: white;
      border: 1px solid #ddd;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: opacity 0.3s ease, transform 0.3s ease;
      top: 103px;
      
      border-radius: 10px;
      cursor: pointer;
      margin-bottom: 10px;
      right: 22px;
      position: fixed;
      z-index: 10;
    }
  }
  
  .dropdown-submenu {
    display: flex;
    align-items: center;
    cursor: pointer;
   
    gap: 14px;
   
  }
  
  .submenu-content {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .desktop-menu {
    display: block;
    margin-top: 20px;
  }
  
  .menu-button {
    margin: 1px;
    display: inline-block;
    border-radius: 50px;
    font-weight: bold;
    color: #000;
    padding: 4px 10px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .slide-in {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
  }
  
  .slide-out {
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
  }
  
  /* @media screen and (max-width: 500px) {
    .desktop-menu {
      display: none;
    }
  
    .top-heading {
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
    }
  
    .navbar-container {
      height: 100px;
      overflow: hidden;
      margin-top: -16px;
    }
  
    .logo {
      margin-left: 3rem;
      margin-bottom: 34px;
      width: 687px;
    }
  
    .search-container {
      position: relative;
      width: 761px;
      top: 0;
      right: 43px;
      top: 26px;
    }
  
    .menu-icon {
      display: none;
    }
  
    .user-icon {
      display: block;
      margin-left: -60px;
      padding-top: 10px;
      width: 107px;
    }
  
    .kart-icon {
      display: block;
      margin-top: 9px;
    }
  
    .skinco-logo {
      display: none;
    }
  
    .argatin-logo {
      display: none;
    }
  
    .user-cart-container {
      margin-bottom: 40px;
      margin-right: 20px;
      width: 99px;
    }
  
    .hamburger {
      margin-top: -24px;
      margin-left: 10px;
    }
  } */
  
  @media screen and (max-width: 834px) and (min-width: 501px) {
    .small-user-cart-container {
      display: flex;
      gap: 7px;
    }
    .small-search-icon{
      right: 83px;
    }
  
   
    .dropdown-menu {
      width: 30vw;
    }
  
    .brands-container {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid black;
      padding-bottom: 10px;
    }
  
    .optionsbrand {
      padding: 22px 19px;
      line-height: 40px;
    }
  
    .brand-link {
      font-size: 18px;
      margin: 10px 0;
      transition: background-color 0.3s;
    }
  
    .optionsbrand .brand-link:hover {
      background-color: red;
    }
  
    .brandtext {
      font-size: 22px;
      padding-left: 10px;
    }
  
    .brandaicon {
      margin-top: 2px;
      font-size: 27px;
    }
  }
  
  @media screen and (max-width: 1100px) and (min-width: 835px) {
    .dropdown-menu {
      width: 25vw;
    }
    .small-search-icon{
      right: 95px;
    }
  
  }
  .slide-down {
    transition: transform 0.5s ease-in-out;
    transform: translateY(0);
  }
  
  .slide-down.slide-in {
    transform: translateY(100%);
  }
  
  
  /* @media screen and (max-width: 1700px) and (min-width: 1400px) {
    .hamburger {
      display: none;
    }
  
    .search-container {
      width: 41%;
    }
  
    .logo {
      padding-left: 83px;
      width: 23vw;
    }
  } */
  
  /* @media screen and (max-width: 600px) {
    .navbar-container {
      display: flex;
      align-items: center;
      
  
      width: 100%;
    }
  } */
.buttonstyle {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: "100%";
  background-color: black;
  color: whitesmoke;
  font-size: 18px;
  border-radius: 25px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
}

.style-add {
  margin-top: 34px;
  padding: 2px;
  /* border-radius: 6px; */
  background-color: black;
  display: inline-block;
  /* box-shadow: inset 0 0 4px; */
  font-size: 14px;
  color: white;
}

.style-add:hover {
  background-color: darkgreen;
}

.textinputstyle {
  width: 300px;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    border-color: #4caf50;
  }

  &:focus {
    outline: none;
    border-color: #2196f3; /* Change the border color on focus */
    box-shadow: 0 0 8px rgba(33, 150, 243, 0.5); /* Add a subtle box shadow on focus */
  }
}

.textinputstyle:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.ReactModalPortal > div {
  background-color: rgb(255, 255, 255, 0.3) !important;
}
.salonkart {
  font-weight: 900;
  font-size: 20px;
  color: black;
}
.salonkartcontianer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.totalprice {
  font-weight: 900;
  font-size: 20px;
  color: gray;
}
.freeshipping {
  font-size: 18px;
  color: white;
  background-color: black;
  padding: 13px;
  border-width: 1px;
  border-radius: 20px;
}
.orderSummary {
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
}
.cartorder-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 95vw;
  height: 20vh;
  border: 1px solid black;
  margin-left: 20px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.cartorder-container img {
  height: 18vh;
  border-radius: 9px;
  margin-right: 314px;
}

.quantity-container {
  display: flex;
  gap: 20px;
  font-weight: bold;
}
.quantity-container-inner {
  display: flex;
}

.name-cart-container {
  display: flex;
}
.name-container {
  display: flex;
  gap: 283px;
}

.modalContainer {
  border-radius: 20px;
  overflow: scroll;
}

.container::-webkit-scrollbar {
  width: 4px;
}

.container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 grey;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background: gainsboro;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: darkgrey;
}

.truncate-name {
  width: 200px;
  margin-left: 8px;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.textinputstyleaddress {
  width: 39vw;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 12px;
  height: 7vh;
  margin-right: 65px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;

  &:hover {
    border-color: #4caf50;
  }

  &:focus {
    outline: none;
    border-color: #2196f3;
    box-shadow: 0 0 8px rgba(33, 150, 243, 0.5);
  }
}

.formcontainer {
  display: flex;
  flex-direction: column;
}
.formcontainer1 {
  display: flex;
  gap: 61px;
}
.gif {
  display: flex;
  justify-content: center;
}
/* .order-successful-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.return-to-home{
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-successful-main-container{

} */
.order-successful-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #ffffff; /* Set your desired background color */
}

.order-successful-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gif {
  height: auto;
}

.return-to-home {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: -17px 84px;
  margin-top: -84px;

  align-items: center;

  font-size: 19px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.return-to-home button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: black; /* Set your desired background color */
  color: #ffffff; /* Set your desired text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.return-to-home button:hover {
  background-color: black; /* Set your desired hover background color */
}

@media screen and (max-width: 600px) {
  .form {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.formcontainer {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
@media screen and (max-width: 600px) {
  .formcontainer {
    width: 84%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .textinputStyle {
    width: 168px;
    border-radius: 10px;
    border-width: 1px;
    padding: -2px;
  }
}

@media screen and (max-width: 500px) and (min-width: 350px) {
  .style-add {
    font-size: 12px;
    margin-top: 47px;
  }
  .cartimg-link {
    width: 10vw;
    height: 10vh;
  }

  .salonkart {
    font-weight: 900;
    font-size: 10px;
    color: black;
  }
  

  .cart-name {
    display: flex;

    width: 53vw;
  }

  .name-cart-container {
    display: flex;
    flex-direction: column;
  }
  .name-container {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  .cartorder-container img {
    height: 9vh;
    border-radius: 9px;

    max-width: 93px;
  }
  .cartorder-container {
    width: 87%;
    height: 20vh;
  }
  .formcontainer {
    display: flex;
    flex-direction: column;
  }
  .formcontainer1 {
    display: flex;
    flex-direction: column;
    padding-left: 43px;
    gap: 0px;
  }
  .textinputstyleaddress {
    width: 295px;
    margin-left: 64px;
    height: 66px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 501px) {
  .cartimg-link {
    width: 10vw;
    height: 10vh;
  }

  .cart-name {
    display: flex;

    width: 53vw;
  }

  .name-cart-container {
    display: flex;
    flex-direction: column;
  }
  .name-container {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  .cartorder-container img {
    height: 9vh;
    border-radius: 9px;

    max-width: 93px;
  }
  .cartorder-container {
    width: 87%;
    height: 20vh;
  }
  .formcontainer {
    display: flex;
    flex-direction: column;
  }
  .formcontainer1 {
    display: flex;
    flex-direction: column;
    padding-left: 334px;
    gap: 0px;
  }
  .textinputstyleaddress {
    width: 307px;
    margin-left: 212px;
    height: 66px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .name-cart-container {
    margin-left: 40px;
  }
}

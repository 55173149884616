.ap-otp-input {
    padding: 2px 8px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 4rem;
    width: 25%;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.375rem;
    color: #00192f;
    text-align: center;
    margin: 0;
  }


  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .animate-marquee {
    animation: marquee 25s linear infinite;
  }
  
.newinput-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 15px auto 0rem auto;
    border: 1px solid black;
    max-width: 240px;
    height: 38px;
    border-radius: 10px;
    padding: 5px;
}

.country-code {
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: end;
    padding-right: .3rem;
}
.bar {
    height: 60%;
    width: 1px;
    padding: 0;
    margin: auto 5px;
    background: black;
}
